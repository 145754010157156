import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);
function initSlider() {
    var swiper = new Swiper('.swiper-container', {
        observer: true,
        autoHeight: true,
        observeParents: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}

var swiper = new Swiper('.swiper-container', {
    observer: true,
    observeParents: true,
    loop: true,
    autoHeight: true,
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: '.swiper-ss-button-next',
        prevEl: '.swiper-ss-button-prev',
    },
    onFirstInit:function(swiper)
    {
        swiper.startAutoplay()
    },
});
var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
        /* Toggle between adding and removing the "active" class,
        to highlight the button that controls the panel */
        initSlider();
        this.classList.toggle("active");

        /* Toggle between hiding and showing the active panel */
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
            panel.style.display = "none";
        } else {
            panel.style.display = "block";
        }
    });
}
if ($(window).width() < 960) {
    $('.phone-number a').attr('href', 'tel:' + $.trim($('.phone-number').text()))
}
else {
    $('.phone-number a').attr('href', 'tel:' +  $.trim($('.phone-number').text()))
}